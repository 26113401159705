import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {SENTRY} from '@youtoken/ui.sentry';

const SESSION_STORAGE_KEY = 'widget_session_id';

export const getSessionId = (): string => {
  return SIMPLE_STORAGE.get(SESSION_STORAGE_KEY) || '';
};

export const setSessionId = (sessionId: string) => {
  try {
    SIMPLE_STORAGE.set(SESSION_STORAGE_KEY, sessionId);
  } catch (error: any) {
    SENTRY.capture(error, {
      source: 'setSessionId',
    });
  }
};

export const removeSessionId = (): void => {
  SIMPLE_STORAGE.delete(SESSION_STORAGE_KEY);
};
