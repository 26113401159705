import * as React from 'react';
import axios from 'axios';
import {useSearchParams} from 'next/navigation';
import io, {Socket} from 'socket.io-client';
import {identity} from 'lodash';
import {TRANSPORT} from '@youtoken/ui.transport';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {getDeviceID} from '@youtoken/ui.security-utils';
import {GLOBAL} from '@youtoken/ui.service-global';
import {enhanceErrorInterceptor} from '@youtoken/ui.errors';
import {handleUnauthorizedError} from '@youtoken/ui.resource-auth-me';
import {initEnvironmentService} from '@/services/enviroment';
import {getSessionId, setSessionId} from '@/helpers/sessionId';

initEnvironmentService();

const useRestApiClientWithoutVersion = () => {
  const searchParams = useSearchParams();

  const [sessionId] = React.useState(() => {
    const sessionIdParam = searchParams?.get('sessionId');

    if (sessionIdParam) {
      setSessionId(sessionIdParam);
      return sessionIdParam;
    }

    return getSessionId();
  });

  return React.useMemo(() => {
    const instance = axios.create({
      baseURL: ENVIRONMENT.BACKEND_URL,
      headers: {
        ['x-device-uuid']: getDeviceID(),
        ['x-device-type']: 'web',
        ['x-use-i18n-errors']: true,
        ['x-web-app-env']: ENVIRONMENT.WEB_APP_ENV, // It is used for separate login flow in ramp app users
        ['x-session-id']: sessionId,
      },
    });

    instance.interceptors.request.use(
      config => {
        if (GLOBAL.token) {
          config.headers['Authorization'] = GLOBAL.token;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(...enhanceErrorInterceptor);

    instance.interceptors.response.use(identity, error => {
      // handleMaintenance(error); // todo decide do we need

      if (
        error &&
        error.config &&
        (error.config.url.includes('signout') ||
          error.config.url.includes('signin'))
      ) {
        throw error;
      }

      handleUnauthorizedError(error);

      throw error;
    });

    return instance;
  }, [ENVIRONMENT.BACKEND_URL, ENVIRONMENT.WEB_APP_ENV, sessionId]);
};

let latestSocket: Socket | null = null;

const useSocketClient = (token: string | null) => {
  return React.useMemo(() => {
    if (latestSocket) {
      latestSocket.disconnect();
    }

    latestSocket = io(ENVIRONMENT.SOCKET_URL, {
      transports: ['websocket'],
      autoConnect: true,
      reconnectionDelayMax: 5000,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
      reconnection: true,
      path: '/ws/',
      auth: token
        ? {
            authorization: token,
          }
        : undefined,
    });

    return latestSocket;
  }, [ENVIRONMENT.SOCKET_URL, token]);
};

export default function DataSourcesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  TRANSPORT.API = useRestApiClientWithoutVersion();
  TRANSPORT.SOCKET = useSocketClient(GLOBAL.token);

  return <>{children}</>;
}
