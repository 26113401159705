import * as React from 'react';
import {NotifierWrapper} from 'react-native-notifier';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {show} from '@/services/notifications';

export default function LocalNotificationsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  LOCAL_NOTIFICATIONS.provideHandlers({
    info: ({text, testID}) => {
      show({text, type: 'info', testID});
    },
    error: ({text, testID}) => {
      show({text, type: 'error', testID});
    },
  });

  return <NotifierWrapper>{children}</NotifierWrapper>;
}
