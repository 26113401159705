import * as React from 'react';
import {useRouter} from 'next/navigation';
import {stringifyUrl} from 'query-string';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {handleExplorerUrl} from '@/helpers/utils';
import {ROUTES} from '@/constants/routes';

export default function SharedRouterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {push, back} = useRouter();

  SHARED_ROUTER_SERVICE.provideHandlers(
    {
      NotFound: () => {
        push(ROUTES.NOT_FOUND);
      },
      SignIn: () => {
        push(ROUTES.SIGN_IN);
      },
      SignUp: () => {
        push(ROUTES.SIGN_UP);
      },
      RestorePassword: () => {
        push(ROUTES.RESTORE_PASSWORD);
      },
      // on sign-up
      VerifyEmail: (_name, _params, query) => {
        push(stringifyUrl({url: ROUTES.VERIFY_EMAIL, query}));
      },
      // on sign-in, if applicable
      DeviceConfirm: (_name, _params, query) => {
        push(stringifyUrl({url: ROUTES.DEVICE_CONFIRM, query}));
      },
      // 2fa
      SignInConfirm: (_name, _params, query) => {
        push(stringifyUrl({url: ROUTES.SIGN_IN_CONFIRM, query}));
      },
      Verification: () => {
        push(ROUTES.PLEASE_VERIFY);
      },
      FormAVerificationModal: () => {
        push(ROUTES.FORM_A);
      },
      IdentityVerificationModal: () => {
        push(ROUTES.VERIFICATION_IDENTITY);
      },
      AddressVerificationModal: () => {
        push(ROUTES.VERIFICATION_ADDRESS);
      },
      PleaseVerify: () => {
        push(ROUTES.PLEASE_VERIFY);
      },
      DeleteAccount: () => {
        push(ROUTES.DELETE_ACCOUNT);
      },
      AccountStats: () => {
        push(ROUTES.HISTORY);
      },
      // WalletsList is the main/home screen in web & native apps  -
      // it's equivalent in the widget is the main form
      WalletsList: () => {
        push(ROUTES.MAIN_FORM);
      },
      More: () => {
        push(ROUTES.PROFILE); // todo decide may be use /main
      },
      // I know we're supposed to use dynamic routes for it,
      // but I coudldn't get it working in a client component in the required time
      HistoryItemNew: (_name, {id}) => {
        push(`${ROUTES.TRANSACTION}?id=${id}`);
      },
      Agreement: (_name, {url}) => {
        push(`${ROUTES.AGREEMENT}/?url=${url}`);
      },
      Explorer: (name, {url}) => {
        handleExplorerUrl(url);
      },

      FiatDepositBankWire: (_name, {ticker}) => {
        push(`${ROUTES.BANK_WIRE_DEPOSIT}/?ticker=${ticker}`);
      },

      // NOTE: some routes, that not used in ramp project
      //#region empty routes

      ReSignDisclosures: () => {
        push(ROUTES.DISCLOSURE);
      },

      __CloseModal: () => {
        // NOTE: not use back() here as in checkAccountVerifications (checkAccountVerificationsFallback) function, which is used everywhere
        // this code is called and back() will happened in unpredictable places
        console.log('do nothing');
      },

      //#endregion empty routes
    },
    () => back(),
    () => push(ROUTES.NOT_FOUND)
  );

  return <>{children}</>;
}
