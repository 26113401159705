import * as React from 'react';
import {ErrorBoundary} from '@youtoken/ui.error-boundary';
import {Box} from '@youtoken/ui.primitives';

export default function ErrorBoundaryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    // @ts-ignore 100vh
    <Box height="100vh">
      <ErrorBoundary>{children}</ErrorBoundary>
    </Box>
  );
}
