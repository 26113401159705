import * as React from 'react';
import {Text, Box} from '@youtoken/ui.primitives';
import {type NotificationType} from './types';

type NotifierComponentProps = {
  title: string;
  type: NotificationType;
  testID?: string;
};

export const NotifierComponent: React.FC<NotifierComponentProps> = ({
  title,
  type,
  testID,
}) => {
  return (
    <Box
      height={50}
      width="100%"
      justifyContent="center"
      alignItems="center"
      px={20}
      backgroundColor={type === 'error' ? '$danger-01' : '$interactive-01'}
      testID={`${testID}_${type.toUpperCase()}`}
    >
      <Text color="$text-04" variant="$body-01-medium-accent">
        {title}
      </Text>
    </Box>
  );
};
