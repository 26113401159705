'use client';
import * as React from 'react';
import dynamic from 'next/dynamic';
import NiceModal from '@ebay/nice-modal-react';
import {
  RecaptchaContextProvider,
  TwoFactorAuthContextProvider,
} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {AppearanceAndThemeProvider} from '@youtoken/ui.primitives';
import {DetectLangComponent} from '@youtoken/ui.service-i18n';
import DataSourcesProvider from '@/providers/data-sources-provider';
import ErrorBoundaryProvider from '@/providers/error-boundary-provider';
import LocalNotificationsProvider from '@/providers/local-notifications-provider';
import SharedRouterProvider from '@/providers/shared-router-provider';
import {GTMInitializer} from '@/providers/gtm-initialiser';
import {GlobalStyles} from '@/styles';

// recaptcha does not initialise correctly without these dynamic imports, no idea why yet
const NavBarProvider = dynamic(() => import('@/providers/nav-bar-provider'), {
  ssr: false,
});

const ServicesProvider = dynamic(
  () => import('@/providers/services-provider'),
  {
    ssr: false,
  }
);
// end recaptcha

export default function ClientProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <AppearanceAndThemeProvider>
      <ErrorBoundaryProvider>
        <DataSourcesProvider>
          <NiceModal.Provider>
            <RecaptchaContextProvider>
              <NiceModal.Provider>
                <RecaptchaContextProvider>
                  <NiceModal.Provider>
                    <TwoFactorAuthContextProvider>
                      <LocalNotificationsProvider>
                        <SharedRouterProvider>
                          <GlobalStyles />
                          <DetectLangComponent />
                          <GTMInitializer />
                          <ServicesProvider>
                            <NavBarProvider>{children}</NavBarProvider>
                          </ServicesProvider>
                        </SharedRouterProvider>
                      </LocalNotificationsProvider>
                    </TwoFactorAuthContextProvider>
                  </NiceModal.Provider>
                </RecaptchaContextProvider>
              </NiceModal.Provider>
            </RecaptchaContextProvider>
          </NiceModal.Provider>
        </DataSourcesProvider>
      </ErrorBoundaryProvider>
    </AppearanceAndThemeProvider>
  );
}
