import {getDeviceNameSync} from 'react-native-device-info';

export const getDeviceName = () => {
  return getDeviceNameSync();
};

export const getDeviceNameSanitized = (): string => {
  const deviceName = getDeviceName();
  if (typeof deviceName === 'string') {
    return encodeURI(deviceName);
  }

  return JSON.stringify(deviceName);
};
