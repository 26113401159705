'use client';

import * as React from 'react';

export const init = (GTM_ID: string) => {
  (function (w, d, s, l, i) {
    //@ts-ignore
    w[l] = w[l] || [];
    //@ts-ignore
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    //@ts-ignore
    j.async = true;
    //@ts-ignore
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    //@ts-ignore
    d.head.appendChild(j);

    var noscript = d.createElement('noscript');
    var iframe = d.createElement('iframe');
    iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + i;
    //@ts-ignore
    iframe.style = 'display:none;visibility:hidden';
    iframe.width = '0';
    iframe.height = '0';
    noscript.appendChild(iframe);
    d.body.appendChild(noscript);
  })(window, document, 'script', 'dataLayer', GTM_ID);
};

export const GTMInitializer = () => {
  React.useEffect(() => {
    init('GTM-P9SXGDP');
  }, []);

  return null;
};
