import {Notifier} from 'react-native-notifier';
import {NotifierComponent} from './NotifierComponent';
import {type NotificationConfig} from './types';

export const show = ({text, type = 'info', testID}: NotificationConfig) => {
  Notifier.showNotification({
    title: text,
    Component: NotifierComponent,
    componentProps: {
      type,
      testID,
    },
  });
};
